import React from 'react'
import { graphql } from 'gatsby'

import { HtmlField as HtmlFieldType } from '../../../../generated/graphql'

export const HTML_FIELD_FIELDS = graphql`
  fragment HtmlFieldFields on WpHtmlField {
    type
    content
    cssClass
  }
`

interface Props {
  field: HtmlFieldType
}

export default function HtmlField({ field }: Props) {
  const { type, content } = field

  return (
    <>
      {content && <div className={`mb-4 gfield gfield-${type}`.trim()} dangerouslySetInnerHTML={{ __html: content }} />}
    </>
  )
}
