import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Seo from '@/components/Seo'
import { Seo as SeoType } from '@/types.d'
import GravityForm from '@/components/GravityForm'
import { Gutenberg } from '@/components/Gutenberg'

interface ContactData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      seo: SeoType
      featuredImage: any
      blocks?: any
    }
    mainMenu?: any
    form: any
  }
  pageContext?: any
}

const Contact = ({ data }: ContactData) => {
  // Page data
  const { page, form, mainMenu } = data

  return (
    <Layout title={page?.title} nav={mainMenu}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}

      {page?.blocks && <Gutenberg blocks={page?.blocks} />}

      {form && <GravityForm form={form} />}
    </Layout>
  )
}

export const ContactPageQuery = graphql`
  query ContactPageById($id: String, $formID: Int) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      uri
      ...seo
      ...pageBlocks
    }
    mainMenu: wpMenu(slug: { eq: "main-menu" }) {
      ...navigation
    }
    form: wpGravityFormsForm(formId: { eq: $formID }) {
      ...GravityFormFields
    }
  }
`

export default Contact
