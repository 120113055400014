import * as React from 'react'
import { graphql } from 'gatsby'
import { useMutation, gql } from '@apollo/client'
import LoadIcon from './assets/symbol-wheel.png'

import { GravityFormsForm as GravityFormsFormType, FormField, FieldError } from '../../../generated/graphql'
import useGravityForm from '../../hooks/useGravityForm'
import GravityFormsField from './GravityFormsField'

export const GRAVITY_FORM_FIELDS = graphql`
  fragment GravityFormFields on WpGravityFormsForm {
    formId
    id
    title
    description
    button {
      text
    }
    confirmations {
      isDefault
      message
    }
    formFields {
      nodes {
        id
        type
        ...AddressFieldFields
        ...CheckboxFieldFields
        ...DateFieldFields
        ...EmailFieldFields
        ...MultiSelectFieldFields
        ...NameFieldFields
        ...PhoneFieldFields
        ...RadioFieldFields
        ...SelectFieldFields
        ...TextFieldFields
        ...TextAreaFieldFields
        ...TimeFieldFields
        ...WebsiteFieldFields
        ...HtmlFieldFields
      }
    }
  }
`

const SUBMIT_FORM = gql`
  mutation submitForm($id: Int!, $fieldValues: [FieldValuesInput]) {
    submitGravityFormsForm(input: { formId: $id, fieldValues: $fieldValues }) {
      entryId
      errors {
        id
        message
      }
    }
  }
`

interface Props {
  form: GravityFormsFormType
}

export default function GravityFormsForm({ form }: Props) {
  const [submitForm, { data, loading, error }] = useMutation(SUBMIT_FORM)
  const haveEntryId = Boolean(data?.submitGravityFormsForm?.entryId)
  const haveFieldErrors = Boolean(data?.submitGravityFormsForm?.errors?.length)
  const wasSuccessfullySubmitted = haveEntryId && !haveFieldErrors
  const defaultConfirmation = form?.confirmations?.find(confirmation => confirmation?.isDefault)
  const formFields = form?.formFields?.nodes || []
  const { state } = useGravityForm()

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (loading) return

    submitForm({
      variables: {
        id: form.formId,
        fieldValues: state,
      },
    }).catch(error => console.error(error))
  }

  function getFieldErrors(id: number): FieldError[] {
    if (!haveFieldErrors) return []
    return data.submitGravityFormsForm.errors.filter((error: FieldError) => error.id === id)
  }

  if (wasSuccessfullySubmitted) {
    return <p className="text-4xl">{defaultConfirmation?.message || 'Form successfully submitted - thank you.'}</p>
  }

  return (
    <form className="font-helvetica flex flex-wrap relative gravity-form" method="post" onSubmit={handleSubmit}>
      {formFields.map(field => (
        <GravityFormsField key={field?.id} field={field as FormField} fieldErrors={getFieldErrors(Number(field?.id))} />
      ))}
      {error ? <p className="error-message">{error.message}</p> : null}

      {!loading && (
        <button
          className={`absolute right-0 -bottom-20 inline-block border-4 px-4 pb-1 pt-2 2xl:px-9 2xl:leading-[2.3rem] text-2xl md:text-4xl lg:text-5xl rounded-full 2xl:rounded-3xl font-serif border-primary text-secondary focus:border-secondary  bg-primary`}
          type="submit"
          disabled={loading}
        >
          {form?.button?.text || 'Tilaa'}
        </button>
      )}
      {loading && (
        <img width="50" height="50" className="animate-spin w-12 absolute right-0 -bottom-20" src={LoadIcon} alt="" />
      )}
    </form>
  )
}
