import * as React from 'react'
import { graphql } from 'gatsby'

import { TextField as TextFieldType, FieldError } from '../../../../generated/graphql'
import useGravityForm, { ACTION_TYPES, FieldValue, StringFieldValue } from '../../../hooks/useGravityForm'

export const TEXT_FIELD_FIELDS = graphql`
  fragment TextFieldFields on WpTextField {
    id
    label
    description
    cssClass
    isRequired
    placeholder
  }
`

interface Props {
  field: TextFieldType
  fieldErrors: FieldError[]
}

const DEFAULT_VALUE = ''

export default function TextField({ field, fieldErrors }: Props) {
  const { id, type, description, isRequired, placeholder } = field
  const htmlId = `field_${id}`
  const { state, dispatch } = useGravityForm()
  const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as StringFieldValue | undefined
  const value = fieldValue?.value || DEFAULT_VALUE

  return (
    <div className={`px-4 mb-10 w-full lg:w-1/2 gfield gfield-${type}`.trim()}>
      {/* <label className="block text-latte uppercase font-bold mb-1 text-left" htmlFor={htmlId}>
        {label}
      </label> */}
      <input
        className="bg-gray border p-4 w-full mb-2 text-xl leading-wide focus:outline-none focus:shadow-outline"
        type="text"
        name={String(id)}
        id={htmlId}
        required={Boolean(isRequired)}
        placeholder={placeholder || ''}
        value={value}
        onChange={event => {
          dispatch({
            type: ACTION_TYPES.updateTextFieldValue,
            fieldValue: {
              id,
              value: event.target.value,
            },
          })
        }}
      />
      {description ? <p className="field-description">{description}</p> : null}
      {fieldErrors?.length
        ? fieldErrors.map(fieldError => (
            <p key={fieldError.id} className="error-message">
              {fieldError.message}
            </p>
          ))
        : null}
    </div>
  )
}
