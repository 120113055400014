import * as React from 'react'

import { GravityFormsForm as GravityFormsFormType } from '../../../generated/graphql'
import { GravityFormProvider } from '../../hooks/useGravityForm'
import GravityFormsForm from './GravityFormsForm'

interface Props {
  form: GravityFormsFormType
}

export default function GravityForm(props: Props) {
  return (
    <section className="mx-auto max-w-6xl mb-24">
      <GravityFormProvider>
        <GravityFormsForm {...props} />
      </GravityFormProvider>
    </section>
  )
}
