import * as React from 'react'
import { graphql } from 'gatsby'

import { SelectField as SelectFieldType, FieldError } from '../../../../generated/graphql'
import useGravityForm, { ACTION_TYPES, FieldValue, StringFieldValue } from '../../../hooks/useGravityForm'

export const SELECT_FIELD_FIELDS = graphql`
  fragment SelectFieldFields on WpSelectField {
    id
    label
    description
    cssClass
    isRequired
    defaultValue
    choices {
      text
      value
    }
  }
`

interface Props {
  field: SelectFieldType
  fieldErrors: FieldError[]
}

export default function SelectField({ field, fieldErrors }: Props) {
  const { id, type, label, description, isRequired, defaultValue, choices } = field
  const htmlId = `field_${id}`
  const { state, dispatch } = useGravityForm()
  const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as StringFieldValue | undefined
  const value = fieldValue?.value || String(defaultValue)

  return (
    <div className={`mb-4 gfield gfield-${type}`.trim()}>
      <label className="block text-gray-700 uppercase font-bold mb-2" htmlFor={htmlId}>
        {label}
      </label>
      <select
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        name={String(id)}
        id={htmlId}
        required={Boolean(isRequired)}
        value={value}
        onChange={event => {
          dispatch({
            type: ACTION_TYPES.updateSelectFieldValue,
            fieldValue: {
              id,
              value: event.target.value,
            },
          })
        }}
      >
        {choices?.map(choice => (
          <option key={choice?.value || ''} value={choice?.value || ''}>
            {choice?.text || ''}
          </option>
        ))}
      </select>
      {description ? <p className="field-description">{description}</p> : null}
      {fieldErrors?.length
        ? fieldErrors.map(fieldError => (
            <p key={fieldError.id} className="error-message">
              {fieldError.message}
            </p>
          ))
        : null}
    </div>
  )
}
